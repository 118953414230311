import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { GoldCoin } from '../Coins';
import { useAuth } from '../../contexts/AuthContext';
import { useBalance } from '../../contexts/BalanceContext';
import { toast } from 'react-toastify';
import { supabase } from '../../api/supabaseClient';

interface TipRainModalProps {
    open: boolean;
    onClose: () => void;
    onCloseWalletModal: () => void;
}

const TipRainModal: React.FC<TipRainModalProps> = ({ open, onClose }) => {
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const [isSending, setIsSending] = useState(false);

    const textFieldSx = {
        '& label.Mui-focused': {
            color: '#efbf04',
        },
        '& .MuiOutlinedInput-root': {
            color: '#efbf04',
            '& fieldset': {
                borderColor: '#efbf04',
            },
            '&:hover fieldset': {
                borderColor: '#ffd700',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffd700',
            },
        },
        '& .MuiInputLabel-root': {
            color: '#efbf04',
        },
        '& input': {
            color: '#efbf04',
        },
    };

    const handleSubmit = async () => {
        setIsSending(true);
        const { data, error } = await supabase.rpc('tip_rain', {
            v_player_id: session?.user.id,
            v_amount: amount
        });
        if (error) {
            console.error('Error tipping rain:', error);
            toast.error(error.message);
        } else {
            console.log('Rain tipped successfully:', data);
            toast.success('Rain tipped successfully!');
            onClose();
        }
        setIsSending(false);
    };

    const handleAmountChange = (value: string) => {
        if (value === '') {
            setAmount('');
            return;
        }

        // Remove any non-numeric or non-decimal characters
        value = value.replace(/[^\d.]/g, '');

        // Ensure only one decimal point
        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts.slice(1).join('');
        }

        // Limit to 2 decimal places
        if (parts.length === 2 && parts[1].length > 2) {
            value = parts[0] + '.' + parts[1].slice(0, 2);
        }

        const numValue = Number(value);
        if (!isNaN(numValue) && numValue >= 0) {
            setAmount(value);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                background: 'radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%)',
                border: '2px solid #efbf04',
                boxShadow: '0 0 20px rgba(239, 191, 4, 0.3)',
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" textAlign="center" component="h2" sx={{ 
                    mb: 4, 
                    fontWeight: 'bold',
                    color: '#efbf04',
                    textTransform: 'uppercase',
                    letterSpacing: '0.05em',
                }}>
                    Tip Rain
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        fullWidth
                        type="number"
                        value={amount}
                        onChange={(e) => handleAmountChange(e.target.value)}
                        onBlur={() => {
                            if (amount) {
                                setAmount(Number(amount).toFixed(2));
                            }
                        }}
                        inputProps={{
                            min: "0",
                            step: "0.01",
                            pattern: "^[0-9]*[.]?[0-9]*$"
                        }}
                        label="Amount in Tokens"
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                        sx={textFieldSx}
                    />
                </Box>
                <Typography variant="body1" sx={{ 
                    mb: 2, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    color: '#efbf04',
                }}>
                    <span className="text-[#efbf04] font-bold text-[1.3rem]">{amount || '0'}</span> 
                    <GoldCoin additionalStyles="mx-2 mb-1" style={{ width: '20px', height: '20px' }} />
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button 
                        onClick={onClose}
                        sx={{
                            color: '#efbf04',
                            borderColor: '#efbf04',
                            '&:hover': {
                                borderColor: '#ffd700',
                                color: '#ffd700',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSending}
                        sx={{
                            bgcolor: '#efbf04',
                            color: '#000',
                            '&:hover': {
                                bgcolor: '#ffd700',
                            },
                            '&:disabled': {
                                bgcolor: 'rgba(239, 191, 4, 0.5)',
                            },
                        }}
                    >
                        Tip Rain
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default TipRainModal;