import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GoldCoin } from '../Coins';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(239, 191, 4, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(239, 191, 4, 0); }
  100% { box-shadow: 0 0 0 0 rgba(239, 191, 4, 0); }
`;

const ChallengeCard = styled(Paper)({
    marginTop: '20px',
    padding: '32px',
    width: '100%',
    maxWidth: '800px',
    background: 'linear-gradient(135deg, hsla(24, 12.82%, 17%, 0.95) 0%, hsla(24, 12.82%, 12%, 0.95) 100%)',
    borderRadius: '16px',
    border: '1px solid hsla(45, 100%, 50%, 0.2)',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    '&:hover': {
        transform: 'translateY(-2px)',
        border: '1px solid hsla(45, 100%, 50%, 0.4)',
        boxShadow: '0 8px 32px rgba(0,0,0,0.3)',
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        background: 'linear-gradient(90deg, transparent, #efbf04, transparent)',
    }
});

const Title = styled(Typography)({
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#efbf04',
    textAlign: 'center',
    marginBottom: '24px',
    textShadow: '0 0 10px rgba(239, 191, 4, 0.3)',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '-8px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '80px',
        height: '2px',
        background: 'linear-gradient(90deg, transparent, #efbf04, transparent)',
    }
});

const Description = styled(Typography)({
    fontSize: '24px',
    color: '#fff',
    textAlign: 'center',
    marginBottom: '24px',
    fontWeight: 500,
    lineHeight: 1.4,
});

const StatsContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: '48px',
    marginBottom: '24px',
    flexWrap: 'wrap',
});

const StatBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
});

const StatLabel = styled(Typography)({
    fontSize: '16px',
    color: '#999',
    textTransform: 'uppercase',
    letterSpacing: '1px',
});

const StatValue = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '24px',
    color: '#05f783',
    fontWeight: 'bold',
});

const Status = styled(Typography)<{ completed?: boolean }>(({ completed }) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    color: completed ? '#FFD700' : '#05f783',
    textAlign: 'center',
    padding: '12px 24px',
    borderRadius: '8px',
    background: 'hsla(24, 12.82%, 10%, 0.5)',
    animation: completed ? 'none' : `${pulse} 2s infinite`,
}));

const Timer = styled(Typography)({
    fontSize: '18px',
    color: '#999',
    textAlign: 'center',
    '& span': {
        color: '#fff',
        fontWeight: 500,
    }
});

interface Challenge {
    id: number;
    description: string;
    minimum_bet: number;
    reward: number;
    expiry: string;
    completed: boolean;
    completed_by: string | null;
}

interface DailyChallengeProps {
    challenge: Challenge;
}

const DailyChallenge: React.FC<DailyChallengeProps> = ({ challenge }) => {
    const formatCountdown = (expiry: string) => {
        const now = new Date();
        const timeLeft = new Date(expiry).getTime() - now.getTime();
        if (timeLeft <= 0) return "Expired";
        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <ChallengeCard elevation={0}>
            <Title variant="h4">🎯 Daily Challenge</Title>
            
            <Description>{challenge.description}</Description>
            
            <StatsContainer>
                {challenge.minimum_bet > 0 && (
                    <StatBox>
                        <StatLabel>Minimum Bet</StatLabel>
                        <StatValue>
                            {challenge.minimum_bet}
                            <GoldCoin style={{ width: '24px', height: '24px' }} />
                        </StatValue>
                    </StatBox>
                )}
                
                {challenge.reward > 0 && (
                    <StatBox>
                        <StatLabel>Reward</StatLabel>
                        <StatValue>
                            {challenge.reward}
                            <GoldCoin style={{ width: '24px', height: '24px' }} />
                        </StatValue>
                    </StatBox>
                )}
            </StatsContainer>

            <Box sx={{ textAlign: 'center', gap: 2, display: 'flex', flexDirection: 'column' }}>
                {challenge.reward > 0 && (
                    <Status completed={challenge.completed}>
                        {challenge.completed ? (
                            <>
                                Completed by{' '}
                                <span style={{ 
                                    color: '#FFD700',
                                    textShadow: '0 0 8px rgba(255, 215, 0, 0.5)',
                                }}>
                                    {challenge.completed_by}
                                </span>
                            </>
                        ) : 'Active'}
                    </Status>
                )}
                
                <Timer>
                    {challenge.completed ? 'Next challenge in: ' : 'Expires in: '}
                    <span>{formatCountdown(challenge.expiry)}</span>
                </Timer>
            </Box>
        </ChallengeCard>
    );
};

export default DailyChallenge;
