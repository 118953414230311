import React from 'react';
import { Link } from 'react-router-dom';
import background from '../assets/background.png';
import logo from '../assets/newlogomaybe2.png';
import { FaDiscord } from 'react-icons/fa';

interface FooterProps {
  removeTopMargin?: boolean;
}

const Footer: React.FC<FooterProps> = ({ removeTopMargin = false }) => {
    return (
        <footer className={`bg-gradient-to-r from-[#1a1a1a] via-[#2d2d2d] via-[#2d2d2d] to-[#1a1a1a] text-white py-4 text-center w-full ${removeTopMargin ? '' : 'mt-20'}`}>
            <div className="container mx-auto px-4">
                <div className="flex flex-col items-center">
                    <div className="flex justify-center items-center mb-4">
                        <img src={logo} alt="Company Logo" className="h-12 mr-4 mb-2" style={{ height: 'auto', width: '100%', maxWidth: '90px', scale: '1.4' }} />
                        <p className="text-[#efbf04]">&copy; 2024 Sand Casino. All rights reserved.</p>
                    </div>
                    <div className="flex justify-center items-center space-x-6 font-runescape text-lg">
                        <Link 
                            to="/TOS" 
                            className="text-gray-300 hover:text-yellow-500 transition-colors"
                        >
                            Terms of Service
                        </Link>
                        <span className="text-gray-600">|</span>
                        <Link 
                            to="/fairness" 
                            className="text-gray-300 hover:text-yellow-500 transition-colors"
                        >
                            Fairness
                        </Link>
                        <span className="text-gray-600">|</span>
                        <a 
                            href="https://discord.gg/QMNgDJVAuX" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="text-gray-300 hover:text-yellow-500 transition-colors flex items-center"
                        >
                            <FaDiscord className="mr-2" />
                            Discord
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
