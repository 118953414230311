import React, { useState } from "react";
import { Modal, Box, Typography, Tabs, Tab, Grid, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../contexts/AuthContext';
import BitcoinIcon from '../../assets/btclogo.png';
import CryptoIcon from '../../assets/crypto.png';
import LitecoinIcon from '../../assets/ltclogo.png';
import GoldIcon from '../../assets/gold.png';
import RSPSIcon from '../../assets/rspslogo.png';
import GoldTransactionModal from './GoldTransactionModal';
import CryptoTransactionModal from './CryptoTransactionModal';
import RSPSDepositModal from './RSPSTransactionModel';
import TipModal from './TipModal';
import { GoldCoin } from '../Coins';

interface WalletModalProps {
    open: boolean;
    onClose: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({ open, onClose }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { session } = useAuth();
    const username = session?.user?.id || 'Guest';
    const [goldModalOpen, setGoldModalOpen] = useState(false);
    const [cryptoModalOpen, setCryptoModalOpen] = useState(false);
    const [tipModalOpen, setTipModalOpen] = useState(false);
    const [rspsModalOpen, setRspsModalOpen] = useState(false);
    const [transactionType, setTransactionType] = useState<'deposit' | 'withdraw'>('deposit');

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleOpenGoldModal = (type: 'deposit' | 'withdraw') => {
        setTransactionType(type);
        setGoldModalOpen(true);
    };

    const handleCloseGoldModal = () => {
        setGoldModalOpen(false);
    };

    const handleOpenCryptoModal = (type: 'deposit' | 'withdraw') => {
        setTransactionType(type);
        setCryptoModalOpen(true);
    };

    const handleOpenTipModal = () => {
        setTipModalOpen(true);
    };

    const handleCloseTipModal = () => {
        setTipModalOpen(false);
    };

    const handleCloseCryptoModal = () => {
        setCryptoModalOpen(false);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '90%', sm: '80%', md: 600 },
                background: 'radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%)',
                border: '2px solid #efbf04',
                boxShadow: '0 0 20px rgba(239, 191, 4, 0.3)',
                p: 4,
                borderRadius: 2,
                color: '#e0e0e0',
            }}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: '#efbf04',
                        '&:hover': {
                            color: '#ffd700',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" sx={{
                    mb: 2,
                    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                    color: '#efbf04',
                    textTransform: 'uppercase',
                    letterSpacing: '0.05em',
                    fontWeight: 'bold',
                }}>
                    Wallet
                </Typography>
                <Tabs 
                    value={tabIndex} 
                    onChange={handleTabChange} 
                    textColor="inherit" 
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#efbf04',
                        },
                        '& .MuiTab-root': {
                            color: '#e0e0e0',
                            '&.Mui-selected': {
                                color: '#efbf04',
                            },
                        },
                    }}
                >
                    <Tab label="Deposit" sx={{ fontWeight: '700', letterSpacing: '0.075em' }} />
                    <Tab label="Withdraw" sx={{ fontWeight: '700', letterSpacing: '0.075em' }} />
                    <Tab label="Tip" sx={{ fontWeight: '700', letterSpacing: '0.075em' }} />
                </Tabs>
                <Box sx={{ mt: 2 }}>
                    {tabIndex === 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2,
                                        bgcolor: 'rgba(45, 45, 45, 0.9)',
                                        border: '1px solid #efbf04',
                                        color: '#efbf04',
                                        '&:hover': {
                                            bgcolor: 'rgba(26, 26, 26, 0.95)',
                                            borderColor: '#ffd700',
                                        },
                                    }}
                                    onClick={() => handleOpenGoldModal('deposit')}
                                >
                                    <img src={GoldIcon} alt="Gold" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Gold</h3>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2,
                                        bgcolor: 'rgba(45, 45, 45, 0.9)',
                                        border: '1px solid #efbf04',
                                        color: '#efbf04',
                                        '&:hover': {
                                            bgcolor: 'rgba(26, 26, 26, 0.95)',
                                            borderColor: '#ffd700',
                                        },
                                    }}
                                    onClick={() => handleOpenCryptoModal('deposit')}
                                >
                                    <img src={CryptoIcon} alt="Crypto" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Crypto</h3>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2,
                                        bgcolor: 'rgba(45, 45, 45, 0.9)',
                                        border: '1px solid #efbf04',
                                        color: '#efbf04',
                                        '&:hover': {
                                            bgcolor: 'rgba(26, 26, 26, 0.95)',
                                            borderColor: '#ffd700',
                                        },
                                    }}
                                    onClick={() => handleOpenGoldModal('withdraw')}
                                >
                                    <img src={GoldIcon} alt="Gold" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Gold</h3>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2,
                                        bgcolor: 'rgba(45, 45, 45, 0.9)',
                                        border: '1px solid #efbf04',
                                        color: '#efbf04',
                                        '&:hover': {
                                            bgcolor: 'rgba(26, 26, 26, 0.95)',
                                            borderColor: '#ffd700',
                                        },
                                    }}
                                    onClick={() => handleOpenCryptoModal('withdraw')}
                                >
                                    <img src={LitecoinIcon} alt="Litecoin" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Litecoin</h3>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 2 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2,
                                        bgcolor: 'rgba(45, 45, 45, 0.9)',
                                        border: '1px solid #efbf04',
                                        color: '#efbf04',
                                        '&:hover': {
                                            bgcolor: 'rgba(26, 26, 26, 0.95)',
                                            borderColor: '#ffd700',
                                        },
                                    }}
                                    onClick={handleOpenTipModal}
                                >   
                                    <GoldCoin style={{ height: '35px' }} />
                                    <h3>Tip Tokens</h3>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
                <GoldTransactionModal
                    open={goldModalOpen}
                    onClose={handleCloseGoldModal}
                    type={transactionType}
                />
                <CryptoTransactionModal
                    open={cryptoModalOpen}
                    onClose={handleCloseCryptoModal}
                    type={transactionType}
                />
                <TipModal
                    open={tipModalOpen}
                    onClose={handleCloseTipModal}
                    onCloseWalletModal={onClose}
                />
            </Box>
        </Modal>
    );
};

export default WalletModal;