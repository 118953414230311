import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Paper, Typography, Box, Divider, Modal, Button, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CloudIcon from '@mui/icons-material/Cloud';
import { GoldCoin } from '../Coins';
import { keyframes } from '@mui/material/styles';
import background from "../../assets/background.png";
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { useChat } from '../../hooks/useChat';
import TipRainModal from '../HeaderAndSidebar/TipRainModal';
import { useVendorStatus } from '../../hooks/useVendorStatus';
import BlockIcon from '@mui/icons-material/Block';
import TimerIcon from '@mui/icons-material/Timer';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TipModal from '../Wallet/TipModal';
import { FaStar, FaTrophy } from "react-icons/fa";
import { useSilverLeaderboard } from '../../hooks/useSilverLeaderboard';

interface ChatPanelProps {
    isOpen: boolean;
    onClose: () => void;
}

const StyledChatPanel = styled('Paper')<{ isopen: string }>(({ isopen }) => ({
    position: 'fixed',
    right: isopen === 'true' ? 0 : '-100%',
    top: '64px',
    width: '100%',
    maxWidth: '340px',
    height: 'calc(100vh - 64px)',
    background: 'linear-gradient(145deg, #1a1a1a 0%, #2d2d2d 100%)',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    transform: isopen === 'true' ? 'translateX(0)' : 'translateX(100%)',
    transition: 'transform 0.3s ease-in-out',
    borderLeft: '1px solid rgba(255, 193, 7, 0.2)',
    '@media (max-width: 480px)': {
        maxWidth: '100%',
        width: '100%',
    }
}));

const ChatHeader = styled('div')({
    padding: '16px',
    borderBottom: '1px solid rgba(255, 193, 7, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#222222',
});

const RainContainer = styled(Box)({
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '72px',
    backgroundColor: '#222222',
    '@media (max-width: 480px)': {
        gap: '8px',
        padding: '0 8px'
    }
});

const RainPool = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    padding: '8px 12px',
    borderRadius: '4px',
    backgroundColor: '#1a1a1a',
    border: '1px solid #efbf04',
    flex: 2,
    height: '100%',
    '&:hover': {
        border: '1px solid #efbf04',
        backgroundColor: '#2a2a2a',
    }
});

const RainButton = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1a1a1a',
    padding: '8px 16px',
    borderRadius: '4px',
    border: '1px solid rgba(255, 193, 7, 0.2)',
    flex: 1,
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#2a2a2a',
        border: '1px solid rgba(255, 193, 7, 0.6)',
    },
});

const ChatMessages = styled('div')({
    flex: 1,
    overflowY: 'auto',
    padding: '16px',
    paddingBottom: '120px',
    '@media (max-width: 600px)': {
        paddingBottom: '16px',
    },
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '3px',
    },
});

const Message = styled('div')<{ msgtype: string }>(({ msgtype }) => ({
    marginBottom: '16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'flex-start',
    padding: '8px 12px',
    borderRadius: '8px',
    maxWidth: '100%',
    border: '1px solid #efbf04',
    backgroundColor: msgtype === 'announcement'
        ? 'rgba(255, 193, 7, 0.1)'
        : msgtype === 'tip' || msgtype === 'raintip'
            ? 'rgba(5, 247, 131, 0.1)'
            : 'rgba(26, 26, 26, 0.8)',
    '&:hover': {
        backgroundColor: msgtype === 'announcement'
            ? 'rgba(255, 193, 7, 0.15)'
            : msgtype === 'tip' || msgtype === 'raintip'
                ? 'rgba(5, 247, 131, 0.15)'
                : 'rgba(26, 26, 26, 0.9)',
    },
    '@media (max-width: 480px)': {
        padding: '6px 8px',
        gap: '8px'
    }
}));

const Avatar = styled('div')({
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    background: 'hsla(24, 12.82%, 35%, 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    flexShrink: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (max-width: 480px)': {
        width: '40px',
        height: '40px',
        fontSize: '1rem'
    }
});

const InputContainer = styled('div')({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: '20px',
    display: 'flex',
    padding: '16px',
    marginBottom: '40px',
    gap: '8px',
    zIndex: 10,
    width: 'calc(100% - 20px)',
    '@media (max-width: 600px)': {
        position: 'static',
        padding: '8px',
        marginTop: '-1px',
        right: 0,
        width: '100%',
    }
});

const Input = styled('input')({
    flex: 1,
    padding: '8px 12px',
    backgroundColor: 'rgba(26, 26, 26, 0.4)',
    backdropFilter: 'blur(8px)',
    border: '1px solid rgba(255, 193, 7, 0.2)',
    borderRadius: '4px',
    color: 'white',
    outline: 'none',
    '&:focus': {
        borderColor: 'rgba(255, 193, 7, 0.6)',
        backgroundColor: 'rgba(42, 42, 42, 0.6)',
    },
    '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.5)',
    },
});

const CloseButton = styled(IconButton)({
    position: 'absolute',
    top: '16px',
    right: '16px',
    color: 'white',
    height: '40px',
    width: '40px',
});

const rainAnimation = keyframes`
  0% {
    transform: translateY(-20px) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(calc(100vh - 64px)) rotate(360deg);
    opacity: 0;
  }
`;

const RainingToken = styled('div')({
    position: 'fixed',
    top: '64px',
    pointerEvents: 'none',
    zIndex: 1001,
    animation: `${rainAnimation} 2s linear forwards`,
});

const Badge = styled('img')({
    width: '20px',
    height: '20px',
    marginLeft: '4px',
    verticalAlign: 'middle',
});

export const ChatPanel: React.FC<ChatPanelProps> = ({ isOpen, onClose }) => {
    const { messages, message, setMessage, rainAmount, handleSendMessage, messagesEndRef, formatAnnouncement, formatTips, formatRainTip } = useChat();
    const { session } = useAuth();
    const { isModerator } = useVendorStatus();
    const [isRainModalOpen, setIsRainModalOpen] = useState(false);
    const [rainingTokens, setRainingTokens] = useState<{ id: number; left: number; size: number }[]>([]);
    const [countdown, setCountdown] = useState<string>('');
    const [isHovering, setIsHovering] = useState(false);
    const [moderationModalOpen, setModerationModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<{ id: string; name: string } | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isTipModalOpen, setIsTipModalOpen] = useState(false);
    const silverLeaderboard = useSilverLeaderboard();

    const trophyColors = [
        'hsla(45, 100%, 50%, 1)',    // Keep Vivid Gold
        'hsla(0, 0%, 80%, 1)',       // Keep Bright Silver
        'hsla(30, 80%, 50%, 1)'      // Keep Vivid Bronze
    ];
    const getLeaderboardRank = (username: string) => {
        return silverLeaderboard.findIndex(player => player.username === username);
    };

    const createRainEffect = () => {
        const newTokens = Array.from({ length: 20 }, (_, i) => ({
            id: Date.now() + i,
            left: Math.random() * 100,
            size: Math.random() * 10 + 15
        }));

        setRainingTokens(prev => [...prev, ...newTokens]);

        setTimeout(() => {
            setRainingTokens(prev => prev.filter(token =>
                token.id < Date.now() - 2000
            ));
        }, 2000);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
            scrollToBottom();
        }
    };

    useEffect(() => {
        // Function to calculate time until next rain
        const getTimeUntilNextRain = () => {
            const now = new Date();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();

            // Calculate minutes and seconds until next rain (at :00)
            let minutesLeft = 60 - minutes;
            let secondsLeft = 60 - seconds;

            if (secondsLeft === 60) {
                secondsLeft = 0;
            } else {
                minutesLeft -= 1;
            }

            return `${minutesLeft}:${secondsLeft.toString().padStart(2, '0')}`;
        };

        // Check for rain time and update countdown
        const timer = setInterval(() => {
            const now = new Date();
            if (now.getMinutes() === 0 && now.getSeconds() === 0) {  // Changed from % 30 to === 0
                createRainEffect();
            }

            if (isHovering) {
                setCountdown(getTimeUntilNextRain());
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [isHovering]);

    const handleMessageClick = (event: React.MouseEvent<HTMLElement>, user: { id: string; name: string }) => {
        setSelectedUser(user);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedUser(null);
    };

    const moderateUser = async (username: string, banType: 'timeout' | 'ban') => {
        if (!selectedUser) return;
        try {
            const { error } = await supabase
                .rpc('moderate_user', {
                    v_username: username,
                    v_bantype: banType,
                    v_moderator_id: session?.user.id
                });

            if (error) throw error;
            toast.success(`User ${username} has been ${banType}ned`);
        } catch (error) {
            console.error('Error moderating user:', error);
            toast.error('Failed to moderate user');
        }
    };

    return (
        <StyledChatPanel isopen={isOpen.toString()}>
            {rainingTokens.map(token => (
                <RainingToken
                    key={token.id}
                    style={{
                        left: `${token.left}%`,
                        animationDelay: `${Math.random() * 0.5}s`
                    }}
                >
                    <GoldCoin style={{
                        width: `${token.size}px`,
                        height: `${token.size}px`
                    }} />
                </RainingToken>
            ))}

            <Divider />
            <ChatHeader>
                <RainContainer>
                    <RainPool
                        onClick={createRainEffect}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <CloudIcon sx={{ color: 'gold', fontSize: '1.2rem' }} />
                            <Typography sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '0.9rem',
                                textTransform: 'uppercase',
                                letterSpacing: '0.05em'
                            }}>
                                Rain {isHovering ? `(${countdown})` : ''}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <GoldCoin style={{ width: '20px', height: '20px' }} />
                            <Typography sx={{
                                color: '#05f783',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                {rainAmount}
                            </Typography>
                        </Box>
                    </RainPool>
                    <RainButton onClick={() => setIsRainModalOpen(true)}>
                        <CloudIcon sx={{ color: 'gold', fontSize: '1.2rem', marginRight: 1 }} />
                        <Typography sx={{
                            color: 'gold',
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                        }}>
                            Tip Rain
                        </Typography>
                    </RainButton>
                </RainContainer>
            </ChatHeader>

            {/* Mobile Input */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <InputContainer>
                    <Input
                        placeholder={`${session ? 'Type a message...' : 'Sign in to chat'}`}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        disabled={!session}
                        onKeyPress={handleKeyPress}
                    />
                    <IconButton
                        onClick={handleSendMessage}
                        disabled={!session}
                        sx={{
                            color: 'white',
                            backgroundColor: '#2a2a2a',
                            '&:hover': {
                                backgroundColor: '#3a3a3a',
                            },
                            '&:disabled': {
                                backgroundColor: '#1a1a1a',
                                color: 'rgba(255, 255, 255, 0.3)',
                            }
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </InputContainer>
            </Box>

            <ChatMessages>
                {messages.map((msg) => (
                    <Message
                        key={msg.id}
                        msgtype={msg.type}
                        onClick={(e) => handleMessageClick(e, msg.user)}
                        style={{ cursor: 'pointer' }}
                    >
                        {msg.type === 'announcement' ? (
                            <>
                                <Avatar
                                    style={{
                                        backgroundImage: msg.user.avatar_url ? `url(${msg.user.avatar_url})` : 'none',
                                    }}
                                >
                                    {!msg.user.avatar_url && msg.user.name[0]}
                                </Avatar>
                                <div>
                                    <Typography sx={{
                                        color: 'white',
                                        fontSize: '1rem',
                                        lineHeight: 1.4,
                                        wordBreak: 'break-word',
                                        overflowWrap: 'break-word',
                                        whiteSpace: 'pre-wrap'
                                    }}>
                                        {formatAnnouncement(msg.message)}
                                    </Typography>
                                </div>
                            </>
                        ) : msg.type === 'tip' ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundImage: msg.user.avatar_url ? `url(${msg.user.avatar_url})` : 'none',
                                    }}
                                >
                                    {!msg.user.avatar_url && msg.user.name[0]}
                                </Avatar>
                                <div>
                                    <Typography sx={{
                                        color: 'white',
                                        fontSize: '1rem',
                                        lineHeight: 1.4,
                                        wordBreak: 'break-word',
                                        overflowWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        marginLeft: '12px'
                                    }}>
                                        {formatTips(msg.message)}
                                    </Typography>
                                </div>
                            </div>
                        ) : msg.type === 'raintip' ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundImage: msg.user.avatar_url ? `url(${msg.user.avatar_url})` : 'none',
                                    }}
                                >
                                    {!msg.user.avatar_url && msg.user.name[0]}
                                </Avatar>
                                <div>
                                    <Typography sx={{
                                        color: 'white',
                                        fontSize: '1rem',
                                        lineHeight: 1.4,
                                        wordBreak: 'break-word',
                                        overflowWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        marginLeft: '12px'
                                    }}>
                                        {formatRainTip(msg.message)}
                                    </Typography>
                                </div>
                            </div>
                        ) : (
                            <>
                                <Avatar
                                    style={{
                                        backgroundImage: msg.user.avatar_url ? `url(${msg.user.avatar_url})` : 'none',
                                    }}
                                >
                                    {!msg.user.avatar_url && msg.user.name[0]}
                                </Avatar>
                                <div>
                                    {!msg.type.includes('announcement') && (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                            <Typography sx={{
                                                color: '#05f783',
                                                fontSize: '1.1rem',
                                                mb: 0.5,
                                                fontWeight: 600
                                            }}>
                                                {msg.user.name}
                                            </Typography>
                                            {msg.user.earlyadopter && (
                                                <Tooltip
                                                    title="Early Adopter"
                                                    placement="top"
                                                    arrow
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: '#080808',
                                                                color: '#efbf04',
                                                                fontSize: '1rem',
                                                                padding: '4px 8px',
                                                                border: '1px solid #efbf04',
                                                                fontWeight: 600,
                                                                '& .MuiTooltip-arrow': {
                                                                    color: '#080808',
                                                                    '&::before': {
                                                                        border: '1px solid #efbf04'
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <span>
                                                        <FaStar
                                                            style={{
                                                                color: '#efbf04',
                                                                width: '16px',
                                                                height: '16px',
                                                                marginLeft: '4px',
                                                                marginBottom: '6px'
                                                            }}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            )}
                                            {msg.user.level > 0 && (
                                                <Tooltip
                                                    title={`Level ${msg.user.level}`}
                                                    placement="top"
                                                    arrow
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: '#080808',
                                                                color: '#efbf04',
                                                                fontSize: '1rem',
                                                                padding: '4px 8px',
                                                                border: '1px solid #efbf04',
                                                                fontWeight: 600,
                                                                '& .MuiTooltip-arrow': {
                                                                    color: '#080808',
                                                                    '&::before': {
                                                                        border: '1px solid #efbf04'
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Badge
                                                        src={`/levels/lvl${msg.user.level}.png`}
                                                        alt={`Level ${msg.user.level}`}
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginBottom: '4px'
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {(() => {
                                                const rank = getLeaderboardRank(msg.user.name);
                                                if (rank >= 0 && rank < 3) {
                                                    return (
                                                        <Tooltip
                                                            title={`#${rank + 1} Silver Leaderboard`}
                                                            placement="top"
                                                            arrow
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: '#080808',
                                                                        color: '#efbf04',
                                                                        fontSize: '1rem',
                                                                        padding: '4px 8px',
                                                                        border: '1px solid #efbf04',
                                                                        fontWeight: 600,
                                                                        '& .MuiTooltip-arrow': {
                                                                            color: '#080808',
                                                                            '&::before': {
                                                                                border: '1px solid #efbf04'
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <span>
                                                                <FaTrophy
                                                                    style={{
                                                                        color: trophyColors[rank],
                                                                        width: '16px',
                                                                        height: '16px',
                                                                        marginLeft: '4px',
                                                                        marginBottom: '4px'
                                                                    }}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    );
                                                }
                                                return null;
                                            })()}
                                        </div>
                                    )}
                                    <Typography sx={{
                                        color: 'white',
                                        fontSize: '1rem',
                                        lineHeight: 1.4,
                                        fontWeight: 600,
                                        wordBreak: 'break-word',
                                        overflowWrap: 'break-word',
                                        whiteSpace: 'pre-wrap'
                                    }}>
                                        {msg.message}
                                    </Typography>
                                </div>
                            </>
                        )}
                    </Message>
                ))}
                <div ref={messagesEndRef} />
            </ChatMessages>

            {/* Desktop Input */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <InputContainer>
                    <Input
                        placeholder={`${session ? 'Type a message...' : 'Sign in to chat'}`}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        disabled={!session}
                        sx={{
                            border: '1px solid #efbf04',
                            marginTop: '2px',
                        }}
                        onKeyPress={handleKeyPress}
                    />
                    <IconButton
                        onClick={handleSendMessage}
                        disabled={!session}
                        sx={{
                            color: 'white',
                            backgroundColor: '#2a2a2a',
                            marginBottom: '2px',
                            border: '1px solid #efbf04',
                            '&:hover': {
                                backgroundColor: '#3a3a3a',
                            },
                            '&:disabled': {
                                backgroundColor: '#1a1a1a',
                                color: 'rgba(255, 255, 255, 0.3)',
                            }
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </InputContainer>
            </Box>

            <TipRainModal
                open={isRainModalOpen}
                onClose={() => setIsRainModalOpen(false)}
            />

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'hsla(24, 12.82%, 25%, 100%)',
                        color: 'white',
                    }
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setIsTipModalOpen(true);
                    }}
                    sx={{ color: 'white' }}
                >
                    <AttachMoneyIcon sx={{ mr: 1 }} /> Tip Player
                </MenuItem>
                {isModerator && (
                    <>
                        <MenuItem
                            onClick={() => {
                                moderateUser(selectedUser?.name, 'timeout');
                                handleClose();
                            }}
                            sx={{
                                color: '#2196f3',  // Blue color
                                '&:hover': {
                                    backgroundColor: 'rgba(33, 150, 243, 0.1)'  // Light blue background on hover
                                }
                            }}
                        >
                            <TimerIcon sx={{ mr: 1 }} /> Timeout (1 hour)
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                moderateUser(selectedUser?.name, 'ban');
                                handleClose();
                            }}
                            sx={{
                                color: '#f44336',  // Red color
                                '&:hover': {
                                    backgroundColor: 'rgba(244, 67, 54, 0.1)'  // Light red background on hover
                                }
                            }}
                        >
                            <BlockIcon sx={{ mr: 1 }} /> Ban User
                        </MenuItem>
                    </>
                )}
            </Popover>

            <TipModal
                open={isTipModalOpen}
                onClose={() => setIsTipModalOpen(false)}
                onCloseWalletModal={() => { }}
                username={selectedUser?.name}
            />
        </StyledChatPanel>
    );
};
