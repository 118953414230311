import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete } from '@mui/material';
import { GoldCoin } from '../Coins';
import { useAuth } from '../../contexts/AuthContext';
import { useMisc } from '../../contexts/MiscContext';
import { useBalance } from '../../contexts/BalanceContext';
import { toast } from 'react-toastify';
import { supabase } from '../../api/supabaseClient';
import styled from '@emotion/styled';

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const ModalContent = styled(Box)({
    position: 'relative',
    width: { xs: '90%', sm: 400, md: 450 },
    maxHeight: '90vh',
    overflowY: 'auto',
    backgroundColor: 'rgba(18, 18, 18, 0.95)',
    borderRadius: '12px',
    border: '1px solid #efbf04',
    padding: '24px',
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#efbf04',
        borderRadius: '3px',
    },
});

const Title = styled(Typography)({
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#efbf04',
    textAlign: 'center',
    marginBottom: '24px',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        '& fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.3)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#efbf04',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '18px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#efbf04',
    },
    '& .MuiInputBase-input': {
        color: '#efbf04',
        fontSize: '20px',
    },
    '& .MuiFormHelperText-root': {
        color: '#ff4444',
        fontSize: '14px',
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        '& fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.3)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#efbf04',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '18px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#efbf04',
    },
    '& .MuiInputBase-input': {
        color: '#efbf04',
        fontSize: '20px',
    },
    '& .MuiAutocomplete-option': {
        color: '#efbf04',
        fontSize: '16px',
        '&[data-focus="true"]': {
            backgroundColor: 'rgba(239, 191, 4, 0.1)',
        },
        '&[aria-selected="true"]': {
            backgroundColor: 'rgba(239, 191, 4, 0.2)',
        },
    },
});

const AmountDisplay = styled(Typography)({
    color: '#efbf04',
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '16px',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '24px',
});

const CancelButton = styled(Button)({
    flex: 1,
    padding: '12px',
    color: '#efbf04',
    borderColor: 'rgba(239, 191, 4, 0.3)',
    '&:hover': {
        borderColor: '#efbf04',
        backgroundColor: 'rgba(239, 191, 4, 0.1)',
    },
});

const SendButton = styled(Button)({
    flex: 1,
    background: 'linear-gradient(45deg, #efbf04 30%, #ffd700 90%)',
    color: '#000',
    fontWeight: 'bold',
    padding: '12px',
    fontSize: '16px',
    '&:hover': {
        background: 'linear-gradient(45deg, #ffd700 30%, #efbf04 90%)',
    },
    '&:disabled': {
        background: '#333',
        color: '#666',
    },
});

interface TipModalProps {
    open: boolean;
    onClose: () => void;
    onCloseWalletModal: () => void;
    username?: string;
}

const TipModal: React.FC<TipModalProps> = ({ open, onClose, onCloseWalletModal, username }) => {
    const [recipient, setRecipient] = useState(username || '');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const { userList } = useMisc();
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        if (username) {
            setRecipient(username);
        }
    }, [username]);

    const handleSubmit = async () => {
        if (isSending) return;
        setIsSending(true);
        
        try {
            const { error } = await supabase.rpc('tip_player', {
                v_player_id: session.user.id,
                v_amount: parseFloat(amount),
                v_recipient: recipient
            });

            if (error) throw error;

            toast.success('Tip sent successfully!');
            await fetchBalances();
            onClose();
            onCloseWalletModal();
        } catch (error) {
            console.error('Error sending tip:', error);
            toast.error(error.message);
        } finally {
            setTimeout(() => setIsSending(false), 1000);
        }
    };

    return (
        <StyledModal open={open} onClose={onClose}>
            <ModalContent>
                <Title>Tip Tokens</Title>
                
                <StyledAutocomplete
                    fullWidth
                    value={recipient}
                    onChange={(_, newValue) => setRecipient(newValue?.username || '')}
                    inputValue={recipient}
                    onInputChange={(_, newInputValue) => setRecipient(newInputValue)}
                    options={recipient ? userList.filter(user => 
                        user.username.toLowerCase().includes(recipient.toLowerCase())
                    ) : []}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.username}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Recipient Username"
                            variant="outlined"
                            error={!!error}
                            helperText={error}
                        />
                    )}
                />

                <Box sx={{ mt: 3 }}>
                    <StyledTextField
                        fullWidth
                        type="number"
                        value={amount}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === '') {
                                setAmount('');
                            } else {
                                const numValue = Number(value);
                                if (!isNaN(numValue) && numValue > 0) {
                                    setAmount(value);
                                }
                            }
                        }}
                        label="Amount in Tokens"
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                    />
                </Box>

                <AmountDisplay>
                    {amount || '0'} <GoldCoin style={{ width: '24px', height: '24px' }} /> Tokens
                </AmountDisplay>

                <ButtonContainer>
                    <CancelButton variant="outlined" onClick={onClose}>
                        Cancel
                    </CancelButton>
                    <SendButton 
                        variant="contained" 
                        onClick={handleSubmit}
                        disabled={isSending}
                    >
                        {isSending ? 'Sending...' : 'Send Tip'}
                    </SendButton>
                </ButtonContainer>
            </ModalContent>
        </StyledModal>
    );
};

export default TipModal;