import { useState, useRef, useEffect } from "react";
import { IoIosHand } from "react-icons/io";

interface ButtonProps {
  name: string;
  action: () => void;
  Icon: React.ComponentType<any>;
  disable?: boolean;
  disableDuration?: number;
  shouldntDisable?: boolean;
  additionalStyles?: string;
  additionalButtonStyles?: string;
  additionalIconStyles?: string;
  additionalTextStyles?: string;
}

const ActionButton: React.FC<ButtonProps> = ({
  name,
  action,
  Icon,
  disable,
  disableDuration = 1000,
  shouldntDisable,
  additionalStyles,
  additionalButtonStyles,
  additionalIconStyles,
  additionalTextStyles
}) => {
  const isButtonEnabled = useRef(true);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [, forceUpdate] = useState({});

  const handleButtonClick = () => {
    if (!isButtonEnabled.current || disable) return;
    action();
    if (shouldntDisable) return;

    isButtonEnabled.current = false;
    forceUpdate({});

    timeoutRef.current = setTimeout(() => {
      isButtonEnabled.current = true;
      forceUpdate({});
      timeoutRef.current = null;
    }, disableDuration);
  };

  return (
    <div
      className={`flex flex-col items-center justify-center z-10 ${additionalStyles}`}
    >
      <button
        className={`flex items-center justify-center w-10 lg:w-14 h-10 lg:h-14 border-4 border-[#efbf04] rounded-full m-1 mr-0 lg:m-4 mb-1 lg:mb-2 bg-bovada hover:shadow-lg hover:bg-black ${!isButtonEnabled.current || disable ? "cursor-not-allowed opacity-50" : "cursor-pointer"} ${additionalButtonStyles}`}
        onClick={handleButtonClick}
        disabled={!isButtonEnabled.current || disable}
      >
        <Icon className={`text-white text-1xl lg:text-4xl text-center ${additionalIconStyles}`} />
      </button>
      <h1 className={`text-sm lg:text-2xl font-bold text-center select-none ${disable ? "opacity-50" : "text-[#efbf04]"} ${additionalTextStyles}`}>
        {name}
      </h1>
    </div>
  );
};

export default ActionButton;
