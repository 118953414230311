export const KENO_PAYOUTS = {
    // 1 SELECTED TILE
    LOW_1: [0.70, 1.74],
    MEDIUM_1: [0.40, 2.64],
    HIGH_1: [0.0, 3.84],

    // 2 SELECTED TILES
    LOW_2: [0.0, 2.0, 3.30],
    MEDIUM_2: [0.0, 1.8, 4.7],
    HIGH_2: [0.0, 0.0, 16.64],

    // 3 SELECTED TILES
    LOW_3: [0.0, 1.1, 1.38, 24],
    MEDIUM_3: [0.0, 0.0, 2.8, 50],
    HIGH_3: [0.0, 0.0, 0.0, 80],

    // 4 SELECTED TILES
    LOW_4: [0.0, 0.0, 2.2, 7.9, 80],
    MEDIUM_4: [0.0, 0.0, 1.7, 10.0, 90],
    HIGH_4: [0.0, 0.0, 0.0, 10.0, 250],

    // 5 SELECTED TILES
    LOW_5: [0.0, 0.0, 1.5, 4.2, 13.0, 250],
    MEDIUM_5: [0.0, 0.0, 1.4, 4.0, 14.0, 315],
    HIGH_5: [0.0, 0.0, 0.0, 4.5, 48.0, 375],

    // 6 SELECTED TILES
    LOW_6: [0.0, 0.0, 1.1, 2.0, 6.2, 85.0, 700],
    MEDIUM_6: [0.0, 0.0, 0.0, 3.0, 9.0, 165.0, 750],
    HIGH_6: [0.0, 0.0, 0.0, 0.0, 10.0, 345.0, 775],

    // 7 SELECTED TILES
    LOW_7: [0.0, 0.0, 1.1, 1.5, 3.3, 15.0, 220.0, 600],
    MEDIUM_7: [0.0, 0.0, 0.0, 2.0, 7.0, 25.0, 400.0, 775],
    HIGH_7: [0.0, 0.0, 0.0, 0.0, 6.35, 90.0, 400.0, 825],

    // 8 SELECTED TILES
    LOW_8: [0.0, 0.0, 1.05, 1.5, 2.0, 5.0, 34.0, 100.0, 700],
    MEDIUM_8: [0.0, 0.0, 0.0, 2.0, 3.9, 10.0, 60.0, 400.0, 750],
    HIGH_8: [0.0, 0.0, 0.0, 0.0, 5.0, 20.0, 244.0, 630.0, 900.0],

    // 9 SELECTED TILES
    LOW_9: [0.0, 0.0, 1.1, 1.3, 1.6, 2.0, 6.0, 40.0, 250.0, 1000.0],
    MEDIUM_9: [0.0, 0.0, 0.0, 2.0, 2.5, 4.5, 10.0, 100.0, 500.0, 1000.0],
    HIGH_9: [0.0, 0.0, 0.0, 0.0, 4.0, 10.0, 55.0, 500.0, 800.0, 1000.0],

    // 10 SELECTED TILES
    LOW_10: [0.0, 0.0, 1.1, 1.2, 1.25, 1.5, 3.0, 11.0, 50.0, 250.0, 1000.0],
    MEDIUM_10: [0.0, 0.0, 0.0, 1.5, 2.0, 4.0, 7.0, 26.0, 100.0, 500.0, 1000.0],
    HIGH_10: [0.0, 0.0, 0.0, 0.0, 3.3, 8.0, 13.0, 63.0, 500.0, 800.0, 1000.0]
};
