import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, RadioGroup, FormControlLabel, Radio, TextField, InputAdornment } from '@mui/material';
import { GoldCoin } from '../Coins';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { useBalance } from "../../contexts/BalanceContext.js";
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const ModalContent = styled(Box)({
    position: 'relative',
    width: { xs: '90%', sm: 400, md: 450 },
    maxHeight: '90vh',
    overflowY: 'auto',
    backgroundColor: 'rgba(18, 18, 18, 0.95)',
    borderRadius: '12px',
    border: '1px solid #efbf04',
    padding: '24px',
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#efbf04',
        borderRadius: '3px',
    },
});

const Title = styled(Typography)({
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#efbf04',
    textAlign: 'center',
    marginBottom: '24px',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
});

const StyledRadioGroup = styled(RadioGroup)({
    marginBottom: '24px',
    '& .MuiFormControlLabel-root': {
        marginBottom: '8px',
    },
    '& .MuiRadio-root': {
        color: 'rgba(239, 191, 4, 0.5)',
        '&.Mui-checked': {
            color: '#efbf04',
        },
    },
    '& .MuiTypography-root': {
        color: '#fff',
        fontSize: '16px',
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        '& fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.3)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#efbf04',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '18px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#efbf04',
    },
    '& .MuiInputBase-input': {
        color: '#efbf04',
        fontSize: '20px',
    },
    '& .MuiFormHelperText-root': {
        color: '#ff4444',
        fontSize: '14px',
    },
});

const ConversionText = styled(Typography)({
    color: '#efbf04',
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '16px',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
});

const SubmitButton = styled(Button)({
    background: 'linear-gradient(45deg, #efbf04 30%, #ffd700 90%)',
    color: '#000',
    fontWeight: 'bold',
    padding: '12px 24px',
    fontSize: '16px',
    width: '100%',
    marginTop: '24px',
    '&:hover': {
        background: 'linear-gradient(45deg, #ffd700 30%, #efbf04 90%)',
    },
    '&:disabled': {
        background: '#333',
        color: '#666',
    },
});

interface GoldTransactionModalProps {
    open: boolean;
    onClose: () => void;
    type: 'deposit' | 'withdraw';
}

const GoldTransactionModal: React.FC<GoldTransactionModalProps> = ({ open, onClose, type }) => {
    const [method, setMethod] = useState('07 Gold');
    // Remove the world state
    // const [world, setWorld] = useState('Members world');
    const [gameGoldAmount, setGameGoldAmount] = useState('');
    const [tokenAmount, setTokenAmount] = useState('');
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [rsUsername, setRsUsername] = useState('');
    const osGoldRate = 1; // 1 Gold token = 1M OS Gold for deposits
    const rs3GoldRate = 10; // 1 Gold token = 10M RS3 Gold for deposits
    const withdrawalMultiplier = 1.08;
    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const [errors, setErrors] = useState({
        gameGoldAmount: '',
        rsUsername: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getRate = () => {
        const baseRate = method === '07 Gold' ? osGoldRate : rs3GoldRate;
        return type === 'withdraw' ? baseRate / withdrawalMultiplier : baseRate;
    };

    useEffect(() => {
        // Update values when type changes
        if (gameGoldAmount) {
            const rate = getRate();
            setTokenAmount((parseFloat(gameGoldAmount) / rate).toFixed(2));
        } else if (tokenAmount) {
            const rate = getRate();
            setGameGoldAmount((parseFloat(tokenAmount) * rate).toFixed(2));
        }
    }, [type]);

    const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMethod((event.target as HTMLInputElement).value);
        setGameGoldAmount('');
        setTokenAmount('');
    };

    // Remove the handleWorldChange function
    // const handleWorldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setWorld((event.target as HTMLInputElement).value);
    // };

    const validateAmount = (amount: string) => {
        if (type === 'deposit' && parseFloat(amount) < 50) {
            setErrors(prev => ({
                ...prev,
                gameGoldAmount: 'Minimum deposit amount is 50M'
            }));
            return false;
        }
        setErrors(prev => ({
            ...prev,
            gameGoldAmount: ''
        }));
        return true;
    };

    const handleGameGoldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setGameGoldAmount(value);
        const rate = getRate();
        setTokenAmount(value ? (parseFloat(value) / rate).toFixed(2) : '');
        validateAmount(value);
    };

    const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setTokenAmount(value);
        const rate = getRate();
        setGameGoldAmount(value ? (parseFloat(value) * rate).toFixed(2) : '');
    };

    const handleRsUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRsUsername(event.target.value);
    };

    const openChatWithMessage = () => {
        if (window.Tawk_API) {
            window.Tawk_API.maximize();
            window.Tawk_API.setAttributes({
                name: session?.user?.user_metadata?.username
            }, function(error: any) {
                if (error) {
                    console.error('Error setting Tawk attributes:', error);
                } else {
                    // Send message after attributes are set successfully
                    window.Tawk_API.addEvent('gold-transaction', {
                        amount: type + " " + gameGoldAmount + 'M ' + method,
                        tokens: tokenAmount + ' Gold Tokens',
                        IGN: rsUsername
                    }, function(error: any) {
                        if (error) {
                            console.error('Error sending Tawk event:', error);
                        }
                    });
                }
            });
        } else {
            console.error('Tawk API not available');
        }
    };

    const handleSubmit = async () => {
        if (!validateAmount(gameGoldAmount)) {
            return;
        }
        
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            const { data, error } = await supabase.rpc('create_gold_deposit', {
                p_type: type === 'deposit' ? 'deposit' : 'withdraw',
                p_game: method,
                p_amount: parseFloat(gameGoldAmount),
                p_coins: parseFloat(tokenAmount),
                v_player_id: session.user.id,
            });

            if (!error) {
                if(type === 'withdraw'){
                   await fetchBalances();
                }
                setInfoDialogOpen(true);
                onClose();
            } else {
                throw error;
            }

        } catch (error) {
            console.error('Error creating gold order:', error);
            toast.error('An error occurred while submitting the deposit request. Please try again.');
        } finally {
            setTimeout(() => setIsSubmitting(false), 1000);
        }
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
        setTimeout(() => {
            window.location.replace('/exchanges');
        }, 200);
    };

    return (
        <StyledModal open={open} onClose={onClose}>
            <ModalContent>
                <Title>
                    {type === 'deposit' ? 'Deposit Gold' : 'Withdraw Gold'}
                </Title>

                <StyledRadioGroup value={method} onChange={handleMethodChange}>
                    <FormControlLabel value="07 Gold" control={<Radio />} label="07 Gold" />
                    <FormControlLabel value="RS3 Gold" control={<Radio />} label="RS3 Gold" />
                </StyledRadioGroup>

                <StyledTextField
                    fullWidth
                    type="number"
                    value={gameGoldAmount}
                    onChange={handleGameGoldChange}
                    label={`Amount in ${method}`}
                    error={!!errors.gameGoldAmount}
                    helperText={errors.gameGoldAmount}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography component="span" sx={{ color: '#efbf04', fontWeight: 'bold', mr: 0.5 }}>M</Typography>
                            </InputAdornment>
                        ),
                    }}
                />

                <ConversionText>
                    
                </ConversionText>

                <StyledTextField
                    fullWidth
                    value={rsUsername}
                    onChange={handleRsUsernameChange}
                    label="RS Username"
                    error={!!errors.rsUsername}
                    helperText={errors.rsUsername}
                />

                <SubmitButton
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Processing...' : type === 'deposit' ? 'Create Deposit' : 'Create Withdrawal'}
                </SubmitButton>
            </ModalContent>
        </StyledModal>
    );
};

export default GoldTransactionModal;