import React, { useState, useEffect } from 'react';
import { supabase } from '../../api/supabaseClient';
import { Box, Typography, Paper, Button, styled, useMediaQuery, useTheme, Menu, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useBalance } from '../../contexts/BalanceContext';
import { useVendorStatus } from '../../hooks/useVendorStatus';
import { GoldCoin } from "../Coins";
import { usePayments, PaymentData } from '../../hooks/useExchangeHistory';
import { useRspsDeposits, RspsDepositData } from '../../hooks/useRspsDeposits';
import { useGoldTransactions, GoldTransactionData } from '../../hooks/useGoldTransactions';
import { toast } from 'react-toastify';
import VendorList from './VendorList';
import RSVendorList from './RSVendorList';
import ClaimModal from './ClaimModal';
import ClaimGoldDepositModal from './ClaimGoldDepositModal';
import ClaimGoldWithdrawalModal from './ClaimGoldWithdrawalModal';
import GoldTransactionModal from '../Wallet/GoldTransactionModal';
import CryptoTransactionModal from '../Wallet/CryptoTransactionModal';
import RSPSTransactionModal from '../Wallet/RSPSTransactionModel';
import './ExchangeTable.css';
import { useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface DepositRequest {
    id: string;
    created_at: string;
    user_id: string;
    type: 'gold' | 'crypto' | 'rsps';
    amount: number;
    status: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  color: '#b1b1b1',
  borderRadius: theme.shape.borderRadius,
  margin: '20px 0',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'hsla(204, 19.23%, 40%, 100%)',
  color: 'hsla(39, 6.41%, 90%, 100%)',
  textTransform: 'uppercase',
  fontWeight: 700,
  letterSpacing: '0.05em',
  padding: theme.spacing(1, 2),
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: 'hsla(204, 19.23%, 45%, 100%)',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingRight: '5rem',
  paddingLeft: '5rem',
  paddingTop: '20px',
  background: 'radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%)',
  borderRadius: theme.shape.borderRadius,
}));

const EmptyState: React.FC<{ message: string }> = ({ message }) => (
  <StyledPaper elevation={0}>
    <Typography variant="h6">{message}</Typography>
  </StyledPaper>
);

interface ExchangesProps {
    initialView?: 'gold' | 'crypto' | 'rsps';
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: 'hsla(24, 12.82%, 15%, 100%)', // Darker background
  borderRadius: '20px',
  padding: '3px',
  '& .MuiToggleButton-root': {
    color: 'hsla(39, 6.41%, 70%, 100%)', // Lighter text for better contrast
    border: 'none',
    borderRadius: '18px', // Slightly smaller to fit within the group's border radius
    padding: '8px 16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    '&.Mui-selected': {
      backgroundColor: 'hsla(204, 19.23%, 40%, 100%)',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: 'hsla(24, 12.82%, 25%, 100%)', // Lighter than unselected, darker than selected
    },
    '&:not(.Mui-selected):hover': {
      backgroundColor: 'hsla(24, 12.82%, 20%, 100%)', // Even lighter for unselected hover
    },
  },
}));

const Exchanges: React.FC<ExchangesProps> = ({ initialView = 'gold' }) => {
    const [depositRequests, setDepositRequests] = useState<DepositRequest[]>([]);
    const [withdrawalRequests, setWithdrawalRequests] = useState<DepositRequest[]>([]);
    const [tableView, setTableView] = useState<'gold' | 'crypto' | 'rsps'>(initialView);
    const [goldTransactionType, setGoldTransactionType] = useState<'deposit' | 'withdrawal'>('deposit');
    const [cryptoTransactionType, setCryptoTransactionType] = useState<'deposit' | 'withdrawal'>('deposit');
    const location = useLocation();

    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const { isVendor, isGoldVendor } = useVendorStatus();
    const {payments: cryptoPayments, fetchPaymentData} = usePayments();
    const { deposits: rspsDeposits, mostRecentDeposit } = useRspsDeposits();
    const { deposits: goldDeposits, withdrawals: goldWithdrawals, mostRecentTransaction: mostRecentGoldTransaction } = useGoldTransactions();
    const [claimModalOpen, setClaimModalOpen] = useState(false);
    const [selectedDeposit, setSelectedDeposit] = useState<RspsDepositData | null>(null);
    const [depositModalOpen, setDepositModalOpen] = useState(false);
    const [claimGoldDepositModalOpen, setClaimGoldDepositModalOpen] = useState(false);
    const [claimGoldWithdrawalModalOpen, setClaimGoldWithdrawalModalOpen] = useState(false);
    const [selectedGoldDeposit, setSelectedGoldDeposit] = useState<GoldTransactionData | null>(null);
    const [selectedGoldWithdrawal, setSelectedGoldWithdrawal] = useState<GoldTransactionData | null>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const view = params.get('view') as 'gold' | 'crypto' | 'rsps' | null;
        if (view && ['gold', 'crypto', 'rsps'].includes(view)) {
            setTableView(view);
        }
    }, [location]);

    const handleViewChange = (view: 'gold' | 'crypto' | 'rsps') => {
        setTableView(view);
    };

    const handleClaim = (item: RspsDepositData) => {
        setSelectedDeposit(item);
        setClaimModalOpen(true);
    };

    const handleClaimGold = (item: GoldTransactionData) => {
        if (item.type === 'deposit') {
            setSelectedGoldDeposit(item);
            setClaimGoldDepositModalOpen(true);
        } else {
            setSelectedGoldWithdrawal(item);
            setClaimGoldWithdrawalModalOpen(true);
        }
    };

    const renderExchangeItem = (item: PaymentData | RspsDepositData | GoldTransactionData) => {
        const isCrypto = 'invoice_id' in item;
        const isRsps = 'game' in item && item.game !== '07 Gold' && item.game !== 'RS3 Gold';
        const isGold = 'game' in item && (item.game === '07 Gold' || item.game === 'RS3 Gold');
        const isClickable = 'invoice_link' in item && item.invoice_link;
        
        return (
            <Paper
                key={item.id}
                className="exchange-grid-item"
                sx={{
                    backgroundColor: 'hsla(24, 12.82%, 20%, 100%)',
                    borderRadius: '8px',
                    padding: isMobile ? 1 : 1.5,
                    marginBottom: isMobile ? 1 : 1.5,
                    height: isMobile ? '200px' : '220px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    fontSize: isMobile ? '0.85rem' : '0.95rem',
                    cursor: isClickable ? 'pointer' : 'default',
                    '&:hover': isClickable ? {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    } : {}
                }}
                onClick={() => {
                    if (isClickable && item.invoice_link) {
                        window.open(item.invoice_link, '_blank');
                    }
                }}
            >
                <div className="exchange-grid-item-header">
                    <span className="exchange-grid-item-id">{'Transaction ID: ' + String(item.id).slice(0, 8)}</span>
                    <span className="exchange-grid-item-date">{item.created_at.split(' ')[0]}</span>
                </div>
                <div className="exchange-grid-item-amount">
                    {isCrypto ? (
                        `$${item.amountusd.toFixed(2)} USD`
                    ) : isGold ? (
                        item.type === 'deposit' ? (
                            `${item.amount} ${item.game}`
                        ) : (

                            `${item.coins?.toLocaleString()} tokens`

                        )
                    ) : (
                        `${item.amount} ${item.game}`
                    )}
                </div>
                {isRsps && (
                    <div className="exchange-grid-item-usd-amount">
                        USD: {item.usd_amount}
                    </div>
                )}
                {isGold && (
                    item.type === 'deposit' ? (
                        <div className="exchange-grid-item-coins mb-2">
                            <span className="exchange-grid-item-coins-text text-2xl mb-1">
                                {item.status === 'completed' ? `$${item.usd_amount ? item.usd_amount.toLocaleString() : ''} = ` : ''}
                            </span>
                            <span className="exchange-grid-item-coins-text text-2xl mb-1">
                                {item.status === 'completed' ? `${item.coins ? item.coins.toLocaleString() : ''}` : ''}
                            </span>
                            {item.status === 'completed' ? <GoldCoin additionalStyles="mb-1 ml-2" /> : ''}
                        </div>
                    ) : (
                        <div className="exchange-grid-item-coins mb-2">
                            {item.status === 'completed' ? <GoldCoin additionalStyles="mb-1 mr-2" /> : ''}
                            <span className="exchange-grid-item-coins-text text-2xl mb-1">
                                {item.status === 'completed' ? `${item.coins ? item.coins.toLocaleString() : ''} = ` : ''}
                            </span>
                            <span className="exchange-grid-item-coins-text text-2xl mb-1">
                                {item.status === 'completed' ? `${item.amount ? item.amount.toLocaleString() : ''} ${item.game}` : ``}
                            </span>
                        </div>
                    )
                )}
                <div className="exchange-grid-item-status-container">
                    <span className={`exchange-grid-item-status status-${item.status.toLowerCase()}`}>
                        {item.status}
                    </span>
                    {((isVendor && isRsps && item.status.toLowerCase() === 'pending') ||
                        (isGoldVendor && isGold && item.status.toLowerCase() === 'pending')) && (
                            <StyledButton
                                size="small"
                                onClick={() => isGold ? handleClaimGold(item as GoldTransactionData) : handleClaim(item as RspsDepositData)}
                                sx={{ ml: 1, fontSize: '0.7rem', padding: '2px 8px' }}
                            >
                                Claim
                            </StyledButton>
                        )}
                </div>
            </Paper>
        );
    };

    const handleCreateRequest = () => {
        if (tableView === 'gold') {
            setGoldTransactionType(goldTransactionType);
        } else if (tableView === 'crypto') {
            setCryptoTransactionType(cryptoTransactionType);
        }
        setDepositModalOpen(true);
    };

    const handleCloseDepositModal = () => {
        setDepositModalOpen(false);
    };

    const renderDepositModal = () => {
        switch (tableView) {
            case 'gold':
                return <GoldTransactionModal open={depositModalOpen} onClose={handleCloseDepositModal} type={goldTransactionType} />;
            case 'crypto':
                return <CryptoTransactionModal open={depositModalOpen} onClose={handleCloseDepositModal} type={cryptoTransactionType === 'deposit' ? 'deposit' : 'withdraw'} />;
            case 'rsps':
                return <RSPSTransactionModal open={depositModalOpen} onClose={handleCloseDepositModal} />;
            default:
                return null;
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (view: 'gold' | 'crypto' | 'rsps') => {
        handleViewChange(view);
        handleClose();
    };

    const handleGoldTransactionTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newType: 'deposit' | 'withdrawal' | null,
    ) => {
        if (newType !== null) {
            setGoldTransactionType(newType);
        }
    };

    const handleCryptoTransactionTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newType: 'deposit' | 'withdrawal' | null,
    ) => {
        if (newType !== null) {
            setCryptoTransactionType(newType);
        }
    };

    const renderContent = () => {
        let items: (DepositRequest | PaymentData)[] = [];
        switch (tableView) {
            case 'gold':
                items = goldTransactionType === 'deposit' ? goldDeposits : goldWithdrawals;
                break;
            case 'crypto':
                items = cryptoTransactionType === 'deposit' ? cryptoPayments.filter(p => p.type === 'deposit') : cryptoPayments.filter(p => p.type === 'withdraw');
                break;
        }

        const getCreateRequestText = () => {
            if (tableView === 'gold') {
                return `Create Gold ${goldTransactionType.charAt(0).toUpperCase() + goldTransactionType.slice(1)}`;
            } else if (tableView === 'crypto') {
                return `Create Crypto ${cryptoTransactionType.charAt(0).toUpperCase() + cryptoTransactionType.slice(1)}`;
            } else {
                return `Create ${tableView.charAt(0).toUpperCase() + tableView.slice(1)} Deposit`;
            }
        };

        return (
            <div className="exchange-grid-container" style={{ 
                display: 'grid', 
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(auto-fill, minmax(240px, 1fr))',
                gap: isMobile ? '8px' : '12px'
            }}>
                <Paper
                    className="exchange-grid-item create-request-card"
                    sx={{
                        backgroundColor: 'hsla(204, 19.23%, 40%, 0.2)',
                        borderRadius: '8px',
                        padding: isMobile ? 1 : 1.5,
                        height: isMobile ? '200px' : '220px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        overflow: 'hidden',
                        '&:hover': {
                            backgroundColor: 'hsla(204, 19.23%, 40%, 0.3)',
                            transform: 'translateY(-5px)',
                            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
                        },
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'radial-gradient(circle at center, transparent 0%, hsla(204, 19.23%, 40%, 0.1) 100%)',
                            opacity: 0,
                            transition: 'opacity 0.3s ease',
                        },
                        '&:hover::before': {
                            opacity: 1,
                        },
                    }}
                    onClick={handleCreateRequest}
                >
                    <Box
                        sx={{
                            backgroundColor: 'hsla(204, 19.23%, 40%, 0.8)',
                            borderRadius: '50%',
                            width: isMobile ? '50px' : '70px',
                            height: isMobile ? '50px' : '70px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: isMobile ? 1 : 1.5,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            },
                        }}
                    >
                        <AddIcon sx={{ fontSize: isMobile ? 25 : 35, color: '#fff' }} />
                    </Box>
                    <Typography variant={isMobile ? "body1" : "h6"} sx={{ color: '#fff', marginBottom: 1, fontWeight: 'bold' }}>
                        {getCreateRequestText()}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)', textAlign: 'center' }}>
                        Click here to initiate a new request
                    </Typography>
                </Paper>
                {items.map(item => renderExchangeItem(item))}
            </div>
        );
    };

    return (
        <StyledBox pb={8} minHeight="100vh" px={isMobile ? 2 : 4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 4 }}>
                {isMobile ? (
                    <>
                        <Button
                            onClick={handleClick}
                            endIcon={<ExpandMoreIcon />}
                            variant="contained"
                            color="primary"
                        >
                            {tableView.charAt(0).toUpperCase() + tableView.slice(1)}
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {['gold', 'crypto'].map((option) => (
                                <MenuItem
                                    key={option}
                                    onClick={() => handleMenuItemClick(option as 'gold' | 'crypto')}
                                    selected={tableView === option}
                                >
                                    {option.charAt(0).toUpperCase() + option.slice(1)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : (
                    <div className="exchange-toggle-container">
                        <input type="radio" id="gold" name="exchange-type" value="gold" checked={tableView === 'gold'} onChange={() => handleViewChange('gold')} />
                        <label htmlFor="gold">Gold</label>
                        <input type="radio" id="crypto" name="exchange-type" value="crypto" checked={tableView === 'crypto'} onChange={() => handleViewChange('crypto')} />
                        <label htmlFor="crypto">Crypto</label>
                        <div className="toggle-slider"></div>
                    </div>
                )}
                {(tableView === 'gold' || tableView === 'crypto') && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <StyledToggleButtonGroup
                            value={tableView === 'gold' ? goldTransactionType : cryptoTransactionType}
                            exclusive
                            onChange={tableView === 'gold' ? handleGoldTransactionTypeChange : handleCryptoTransactionTypeChange}
                            aria-label={`${tableView} transaction type`}
                        >
                            <ToggleButton value="deposit" aria-label="deposit">
                                <ArrowUpwardIcon sx={{ mr: 1 }} />
                                Deposit
                            </ToggleButton>
                            <ToggleButton value="withdrawal" aria-label="withdrawal">
                                <ArrowDownwardIcon sx={{ mr: 1 }} />
                                Withdrawal
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    </Box>
                )}
            </Box>
            {renderContent()}
            {tableView === 'gold' && (
                <RSVendorList
                    mostRecentGoldTransaction={mostRecentGoldTransaction}
                    coins={mostRecentGoldTransaction?.coins || null}
                />
            )}
            {selectedDeposit && (
                <ClaimModal
                    open={claimModalOpen}
                    onClose={() => setClaimModalOpen(false)}
                    deposit={selectedDeposit}
                />
            )}
            {selectedGoldDeposit && (
                <ClaimGoldDepositModal
                    open={claimGoldDepositModalOpen}
                    onClose={() => setClaimGoldDepositModalOpen(false)}
                    deposit={selectedGoldDeposit}
                />
            )}
            {selectedGoldWithdrawal && (
                <ClaimGoldWithdrawalModal
                    open={claimGoldWithdrawalModalOpen}
                    onClose={() => setClaimGoldWithdrawalModalOpen(false)}
                    withdrawal={selectedGoldWithdrawal}
                />
            )}
            {renderDepositModal()}
        </StyledBox>
    );
};

export default Exchanges;
