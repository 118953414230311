import { useEffect, useState } from "react";
import { Card } from "../../objects/card";

interface CardProps {
  card: Card;
  lastCard?: boolean;
  showCount?: boolean;
  count?: number;
  active?: boolean;
  style?: React.CSSProperties;
  flipping?: boolean;
  cardImages?: any;
  soft?: boolean;
  freeBet?: boolean;
}

const CardComponent: React.FC<CardProps> = ({
  card,
  lastCard,
  showCount,
  count,
  active,
  style,
  flipping,
  cardImages,
  freeBet,
  soft
}) => {
  const bgColorClass = count && count > (freeBet ? 22 : 21) ? "bg-red-600" : "bg-primary-500";
  const activeClassImage = active
    ? "w-16 md:w-24 lg:w-28"
    : "w-8 lg:w-20 mr-1 opacity-50";
  const lastCardMargin = lastCard ? "" : "mr-3";
  const activeClassCount = active
    ? "w-10 h-10 lg:w-14 lg:h-14 text-lg lg:text-2xl"
    : "w-6 h-6 lg:w-8 lg:h-8 text-sm lg:text-md opacity-50";
  const cardImageUrl = () => {
    if (cardImages) {
      if (card.faceup) {
        const value = getValueForImage();
        const suit = card.suit ? card.suit.toLowerCase() : null;
        return cardImages[`card_${suit}_${value}`];
      } else {
        return cardImages["card_back"];
      }
    }
    if (card.faceup) {
      const value = getValueForImage();
      const suit = card.suit ? card.suit.toLowerCase() : null;

      // Return the URL of the card image in your assets folder
      return `/cards/${suit}_${value}.svg`;
    } else {
      // If the card is not face up, return the back card image
      return `/cards/card_back2.webp`;
    }
  };

  const getValueForImage = () => {
    if (typeof card.value === "number") {
      return card.value;
    } else {
      return card.value ? card.value.toLowerCase() : null;
    }
  };

  return (
    <div className="relative z-10" style={style}>
      <img
        src={cardImages ? cardImageUrl()?.src : cardImageUrl()}
        alt="Card"
        className={`${activeClassImage} ${lastCardMargin} shadow-xl shadow-[#1A2C38] z-10`}
      />
      {lastCard && showCount && card.faceup && (
        <div
          className={`absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 ${bgColorClass} rounded-full ${activeClassCount} flex items-center justify-center text-white font-bold select-none`}
        >
          <span className="flex items-center justify-center w-full h-full">
            {soft && count<21 ? `${count-10}/${count}` : count}
          </span>
        </div>
      )}
    </div>
  );
};
export default CardComponent;