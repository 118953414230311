import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';

interface ReferralStats {
    currentTier: string;
    totalDeposit: number;
    availableFunds: number;
    tierLevel: number;
    commissionPaid: number;
    allReferrals: number;
}

export function useReferralStats() {
    const tiers = [
        { name: 'Bronze', level: 1 },
        { name: 'Iron', level: 2 },
        { name: 'Silver', level: 3 },
        { name: 'Gold', level: 4 },
        { name: 'Platinum', level: 5 },
        { name: 'Diamond', level: 6 }
    ];
    const [referralStats, setReferralStats] = useState<ReferralStats | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const { session } = useAuth();

    const fetchReferralStats = async () => {
        if (!session?.user.id) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            // Fetch referral stats for the current user
            const { data, error } = await supabase
                .from('referrals')
                .select('total_deposits, commission_earned, rank, referrals_count, commission_paid')
                .eq('referrer_id', session?.user?.id)
                .single();

            if (error) {
                setError('Error fetching referral stats');
                console.error('Error fetching referral stats:', error);
            } else {
                setReferralStats({
                    totalDeposit: data.total_deposits || 0,
                    availableFunds: data.commission_earned || 0,
                    tierLevel: data.rank || 1,
                    allReferrals: data.referrals_count || 0,
                    commissionPaid: data.commission_paid || 0,
                    currentTier: tiers.find(tier => tier.level === data.rank)?.name || 'Bronze',
                });
            }
        } catch (err) {
            setError('Unexpected error occurred');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReferralStats();
    }, [session?.user.id]);

    return { referralStats, isLoading, error, refetch: fetchReferralStats };
}
