import React, { useEffect, useState, useRef } from 'react';
import { useBalance } from '../../contexts/BalanceContext';
import { GoldCoin, SilverCoin } from '../Coins';
import './KenoWinningsComponent.css';
import { useVolume } from "../../contexts/VolumeContext";

const KenoWinningsComponent = ({ multiplier, winnings }) => {
    const formattedWinnings = typeof winnings === 'number' ? winnings.toFixed(2) : '0.00';
    const formattedMultiplier = multiplier.toFixed(2);
    const audio = new Audio("/winning.mp3");
    const [visible, setVisible] = useState(true);
    const { balanceType } = useBalance();
    const { mutedStates, volumes } = useVolume();
    const containerRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 2500); // Start fade-out slightly earlier

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        audio.volume = mutedStates['keno'] ? 0 : volumes['keno'];
        audio.play();
    }, []);

    useEffect(() => {
        if (!visible && containerRef.current) {
            containerRef.current.addEventListener('animationend', () => {
                containerRef.current.style.display = 'none';
            }, { once: true });
        }
    }, [visible]);

    if (!visible && !containerRef.current) return null;

    return (
        <div
            ref={containerRef}
            className={`winnings-container ${visible ? "fade-in" : "fade-out"}`}
        >
            <div className="winnings-box">
                <div className="multiplier-text">{formattedMultiplier}×</div>
                <div className="divider"></div>
                <div className="winnings-text">
                    {formattedWinnings}
                    {balanceType === "gold"
                        ? <GoldCoin additionalStyles='ml-2 w-8 h-8' />
                        : <SilverCoin additionalStyles='ml-2 w-8 h-8' />
                    }
                </div>
            </div>
        </div>
    );
};

export default KenoWinningsComponent;