import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, IconButton, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { GoldCoin } from '../Coins';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import background from "../../assets/background.png";
import border from "../../assets/borders.png";
import { useBalance } from '../../contexts/BalanceContext';
import styled from '@emotion/styled';

interface CryptoTransactionModalProps {
    open: boolean;
    onClose: () => void;
    type: 'deposit' | 'withdraw';
}

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const ModalContent = styled(Box)({
    position: 'relative',
    width: { xs: '90%', sm: 400, md: 450 },
    maxHeight: '90vh',
    overflowY: 'auto',
    backgroundColor: 'rgba(18, 18, 18, 0.95)',
    borderRadius: '12px',
    border: '1px solid #efbf04',
    padding: '24px',
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#efbf04',
        borderRadius: '3px',
    },
});

const Title = styled(Typography)({
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#efbf04',
    textAlign: 'center',
    marginBottom: '24px',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
});

const AmountContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '24px',
});

const SwapButton = styled(IconButton)({
    background: 'rgba(239, 191, 4, 0.1)',
    border: '1px solid rgba(239, 191, 4, 0.2)',
    padding: '12px',
    transition: 'all 0.3s ease',
    '&:hover': {
        background: 'rgba(239, 191, 4, 0.2)',
        transform: 'rotate(180deg)',
    },
    '& svg': {
        color: '#efbf04',
        fontSize: '24px',
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        '& fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.3)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#efbf04',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '16px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#efbf04',
    },
    '& .MuiInputBase-input': {
        color: '#fff',
        fontSize: '16px',
    },
    '& .MuiFormHelperText-root': {
        color: '#ff4444',
        fontSize: '14px',
        marginTop: '8px',
    },
});

const MaxButton = styled(Button)({
    minWidth: 'auto',
    padding: '4px 12px',
    color: '#efbf04',
    borderColor: 'rgba(239, 191, 4, 0.3)',
    '&:hover': {
        borderColor: '#efbf04',
        backgroundColor: 'rgba(239, 191, 4, 0.1)',
    },
});

const SubmitButton = styled(Button)({
    background: 'linear-gradient(45deg, #efbf04 30%, #ffd700 90%)',
    color: '#000',
    fontWeight: 'bold',
    padding: '12px 24px',
    fontSize: '16px',
    width: '100%',
    marginTop: '24px',
    '&:hover': {
        background: 'linear-gradient(45deg, #ffd700 30%, #efbf04 90%)',
    },
    '&:disabled': {
        background: '#333',
        color: '#666',
    },
});

const ConversionText = styled(Typography)({
    color: '#efbf04',
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
});

const ReferralContainer = styled(Box)({
    marginTop: '24px',
    display: 'flex',
    gap: '12px',
    alignItems: 'flex-start',
});

const ReferralTextField = styled(TextField)({
    flex: 1,
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        '& fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.3)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(239, 191, 4, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#efbf04',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiInputBase-input': {
        color: '#fff',
    },
    '& .MuiFormHelperText-root': {
        color: '#ff4444',
    },
});

const ApplyButton = styled(Button)({
    height: '56px',
    background: 'linear-gradient(45deg, #efbf04 30%, #ffd700 90%)',
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
        background: 'linear-gradient(45deg, #ffd700 30%, #efbf04 90%)',
    },
    '&:disabled': {
        background: '#333',
        color: '#666',
    },
});

const CryptoTransactionModal: React.FC<CryptoTransactionModalProps> = ({ open, onClose, type }) => {
    const [amount, setAmount] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [amountError, setAmountError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [isUSD, setIsUSD] = useState(true);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const tokenPrice = 0.20;
    const { session } = useAuth();
    const { goldBalance } = useBalance();
    const [referralCode, setReferralCode] = useState('');
    const [isReferralApplied, setIsReferralApplied] = useState(false);
    const [referralError, setReferralError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateAmount = (value: string) => {
        const numAmount = parseFloat(value);
        if (isNaN(numAmount) || numAmount <= 0) {
            setAmountError('Please enter a valid amount greater than 0');
            return false;
        }

        if (type === 'withdraw') {
            const tokensToWithdraw = isUSD ? numAmount / tokenPrice : numAmount;
            if (tokensToWithdraw > goldBalance) {
                setAmountError(`Insufficient balance. Maximum available: ${isUSD ? (goldBalance * tokenPrice).toFixed(2) + ' USD' : goldBalance + ' tokens'}`);
                return false;
            }
        }

        if (isUSD && numAmount < 10) {
            setAmountError('Minimum amount is $10 USD');
            return false;
        }

        setAmountError('');
        return true;
    };

    const validateWalletAddress = (address: string) => {
        const ltcRegex = /^(L|M)[a-km-zA-HJ-NP-Z1-9]{26,33}$|^ltc1[a-zA-HJ-NP-Z0-9]{39,59}$/;
        if (!address) {
            setAddressError('Wallet address is required');
            return false;
        }
        if (!ltcRegex.test(address)) {
            setAddressError('Invalid Litecoin wallet address');
            return false;
        }
        setAddressError('');
        return true;
    };

    const getMaxAmount = () => {
        if (type === 'withdraw') {
            return isUSD
                ? (goldBalance * tokenPrice).toFixed(2)  // Max USD
                : goldBalance.toString();                // Max tokens
        }
        return '';  // No max for deposits
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Allow empty value or valid numbers
        if (value === '' || (!isNaN(Number(value)) && Number(value) >= 0)) {
            if (type === 'withdraw') {
                const numValue = Number(value);
                const maxAmount = isUSD
                    ? goldBalance * tokenPrice
                    : goldBalance;

                // If input exceeds max, set to max
                if (numValue > maxAmount) {
                    setAmount(maxAmount.toString());
                    return;
                }
            }
            setAmount(value);
            if (value !== '') validateAmount(value);
        }
    };

    const handleSwap = () => {
        setIsUSD(!isUSD);
        setAmount(''); // Reset amount when swapping
    };

    const calculateConversion = () => {
        if (amount === '') return '0';
        const numAmount = parseFloat(amount);
        return isUSD
            ? (numAmount / tokenPrice).toFixed(2)
            : (numAmount * tokenPrice).toFixed(2);
    };

    const openChatWithMessage = () => {
        if (window.Tawk_API) {
            window.Tawk_API.maximize();
            window.Tawk_API.setAttributes({
                name: session?.user?.user_metadata?.username
            }, function (error: any) {
                if (error) {
                    console.error('Error setting Tawk attributes:', error);
                } else {
                    // Send message after attributes are set successfully
                    window.Tawk_API.addEvent('crypto-transaction', {
                        type: 'withdrawal',
                        amount: isUSD ? `$${amount} USD` : `${amount} Gold Tokens`,
                    }, function (error: any) {
                        if (error) {
                            console.error('Error sending Tawk event:', error);
                        }
                    });
                }
            });
        } else {
            console.error('Tawk API not available');
        }
    };

    const handleSubmit = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const usdAmount = isUSD ? amount : calculateConversion();
        if (!validateAmount(usdAmount)) {
            setIsSubmitting(false);
            return;
        }

        if (type === 'deposit') {
            try {
                const { data, error } = await supabase.functions.invoke('create-invoice', {
                    body: {
                        amount: parseFloat(usdAmount),
                        userId: session.user.id,
                        type: 'deposit',
                        referralCode: referralCode || null
                    },
                });
                if (data && data.paymentUrl) {
                    window.open(data.paymentUrl, '_blank');
                    onClose();
                    window.location.href = '/exchanges?view=crypto';
                } else {
                    throw error;
                }
            } catch (error) {
                console.error('Error creating invoice:', error);
                toast.error('An error occurred while creating the invoice. Enter valid amount between 10 and 5000 USD');
            } finally {
                setTimeout(() => setIsSubmitting(false), 1000);
            }
        } else {
            try {
                const { data: withdrawCheck, error: withdrawCheckError } = await supabase.rpc('canwithdraw', { v_player_id: session.user.id });
                if (withdrawCheckError) {
                    toast.error(withdrawCheckError.message);
                    return;
                }

                const coinsAmount = isUSD ? parseFloat(calculateConversion()) : parseFloat(amount);

                const { data, error } = await supabase.functions.invoke('create-withdrawal', {
                    body: {
                        userId: session.user.id,
                        amount: parseFloat(usdAmount),
                        walletAddress: walletAddress
                    },
                });

                if (error) throw error;

                if (data?.success) {
                    toast.success('Withdrawal request created successfully');
                    setInfoDialogOpen(true);
                    onClose();
                } else {
                    throw new Error('Failed to create withdrawal request');
                }
            } catch (error) {
                console.error('Error creating withdrawal:', error);
                toast.error('An error occurred while creating the withdrawal request. If you already have a pending withdrawal, please wait for admin approval.');
            } finally {
                setTimeout(() => setIsSubmitting(false), 1000);
            }
        }
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
        onClose();
        window.location.href = '/exchanges?view=crypto';
    };

    const handleMaxClick = () => {
        const maxAmount = getMaxAmount();
        setAmount(maxAmount);
        validateAmount(maxAmount);
    };

    const handleApplyReferral = async () => {
        if (!referralCode) return;

        try {
            const { data, error } = await supabase
                .rpc('is_valid_referral_code', {
                    input_code: referralCode.toLowerCase()
                });

            if (error) throw error;

            if (data) {
                setIsReferralApplied(true);
                setReferralError('');
            } else {
                setIsReferralApplied(false);
                setReferralError('Invalid referral code');
            }
        } catch (error) {
            console.error('Error checking referral code:', error);
            setIsReferralApplied(false);
            setReferralError('Error validating referral code');
        }
    };

    return (
        <>
            <StyledModal open={open} onClose={onClose}>
                <ModalContent>
                    <Title>
                        {type === 'deposit' ? 'Deposit Crypto' : 'Withdraw Litecoin'}
                    </Title>

                    <AmountContainer>
                        <StyledTextField
                            fullWidth
                            type="number"
                            value={amount}
                            onChange={handleAmountChange}
                            label={isUSD ? 'Amount in USD' : 'Amount in Gold tokens'}
                            error={!!amountError}
                            helperText={amountError}
                            InputProps={{
                                endAdornment: type === 'withdraw' && (
                                    <MaxButton
                                        variant="outlined"
                                        onClick={handleMaxClick}
                                    >
                                        MAX
                                    </MaxButton>
                                )
                            }}
                        />
                        <SwapButton onClick={handleSwap}>
                            <SwapHorizIcon />
                        </SwapButton>
                    </AmountContainer>

                    <ConversionText>
                        {isUSD
                            ? <>
                                {amount || '0'} USD = {calculateConversion()} <GoldCoin style={{ width: '24px', height: '24px' }} />
                            </>
                            : <>
                                {amount || '0'} <GoldCoin style={{ width: '24px', height: '24px' }} /> = ${calculateConversion()} USD
                            </>
                        }
                    </ConversionText>

                    {type === 'withdraw' && (
                        <StyledTextField
                            fullWidth
                            value={walletAddress}
                            onChange={(e) => {
                                setWalletAddress(e.target.value);
                                if (e.target.value) validateWalletAddress(e.target.value);
                            }}
                            label="Litecoin Wallet Address"
                            error={!!addressError}
                            helperText={addressError}
                            sx={{ mt: 3 }}
                        />
                    )}

                    {type === 'deposit' && (
                        <ReferralContainer>
                            <ReferralTextField
                                fullWidth
                                label="Referral Code (Optional)"
                                value={referralCode}
                                onChange={(e) => setReferralCode(e.target.value)}
                                error={!!referralError}
                                helperText={referralError}
                                disabled={isReferralApplied}
                            />
                            <ApplyButton
                                variant="contained"
                                onClick={handleApplyReferral}
                                disabled={!referralCode || isReferralApplied}
                            >
                                {isReferralApplied ? 'Applied' : 'Apply'}
                            </ApplyButton>
                        </ReferralContainer>
                    )}

                    <SubmitButton
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Processing...' : type === 'deposit' ? 'Create Invoice' : 'Create Withdrawal'}
                    </SubmitButton>
                </ModalContent>
            </StyledModal>

            <Dialog
                open={infoDialogOpen}
                onClose={handleInfoDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        bgcolor: 'background.paper',
                        backgroundImage: `url(${background})`,
                        borderRadius: 2,
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                        border: '8px solid black',
                        borderImage: `url(${border}) 2 stretch`,
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: 'text.primary', fontWeight: 'bold', textAlign: 'center', pt: 3 }}>
                    Crypto Withdrawal Notice
                </DialogTitle>
                <DialogContent sx={{ px: 4, pb: 4 }}>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '1.2rem' }}>
                            Crypto withdrawals currently require admin approval (this may take some time, we apologize). This is a temporary measure to ensure the integrity of the system.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <Button
                        className='rs-button'
                        variant='contained'
                        onClick={handleInfoDialogClose}
                        sx={{
                            bgcolor: 'secondary.main',
                            color: 'text.primary',
                            fontWeight: 'bold',
                            px: 3,
                            '&:hover': {
                                bgcolor: 'secondary.dark',
                            }
                        }}
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CryptoTransactionModal;