import React, { createContext, useContext, useState } from 'react';

const VolumeContext = createContext();

export const VolumeProvider = ({ children }) => {
    const [volumes, setVolumes] = useState({"blackjack": 0.5,"freebet": 0.5, "duel": 0.5, "dharoks":0.5, "roulette": 0.5, "dice": 0.5, "plinko": 0.5, "flowerpoker": 0.5, "mines": 0.5, "ultimatetexas": 0.5, "palmtower": 0.5, 'keno': 0.5});
    const [mutedStates, setMutedStates] = useState({"blackjack": false, "freebet": false, "duel": false, "dharoks":false, "roulette": false, "dice": false, "plinko": false, "flowerpoker": false, "mines": false, "ultimatetexas": false, "palmtower": false, 'keno': false});
    const [previousVolumes, setPreviousVolumes] = useState({"blackjack": 0.5, "freebet": 0.5, "duel": 0.5, "dharoks":0.5, "roulette": 0.5, "dice": 0.5, "plinko": 0.5, "flowerpoker": 0.5, "mines": 0.5, "ultimatetexas": 0.5, "palmtower": 0.5, 'keno': 0.5}); // Store previous volumes
    const [activeGame, setActiveGame] = useState(null);

    const toggleMute = (gameId) => {
        setMutedStates(prevMutedStates => {
            const newMutedState = !prevMutedStates[gameId];
            return { ...prevMutedStates, [gameId]: newMutedState };
        });
        setVolumes(prevVolumes => {
            if (mutedStates[gameId]) {
                // Unmuting: restore the previous volume
                const previousVolume = previousVolumes[gameId] || 1;
                return { ...prevVolumes, [gameId]: previousVolume };
            } else {
                // Muting: store the current volume and set to 0
                setPreviousVolumes(prev => ({ ...prev, [gameId]: prevVolumes[gameId] || 1 }));
                return { ...prevVolumes, [gameId]: 0 };
            }
        });
    };

    const handleVolumeChange = (gameId, newVolume) => {
        setVolumes(prevVolumes => ({ ...prevVolumes, [gameId]: newVolume }));
        if (newVolume === 0) {
            setMutedStates(prevMutedStates => ({ ...prevMutedStates, [gameId]: true }));
        } else if (mutedStates[gameId] && newVolume > 0) {
            setMutedStates(prevMutedStates => ({ ...prevMutedStates, [gameId]: false }));
        }
    };

    return (
        <VolumeContext.Provider value={{ volumes, mutedStates, toggleMute, handleVolumeChange, activeGame, setActiveGame }}>
            {children}
        </VolumeContext.Provider>
    )
}

export const useVolume = () => {
    return useContext(VolumeContext);
}
