import React from 'react';
import { KENO_PAYOUTS } from './kenoPayouts';
import { GiEmerald } from "react-icons/gi";
import './KenoPayoutTable.css';

const KenoPayoutTable = ({ selectedCount, risk }) => {
    const getPayoutArray = () => {
        const key = `${risk.toUpperCase()}_${selectedCount}`;
        return KENO_PAYOUTS[key] || [];
    };

    const payouts = getPayoutArray();
    const nonZeroPayouts = payouts.map((multiplier, index) => ({
        hits: index,
        multiplier: multiplier > 0 ? multiplier : null
    })).filter(p => p.multiplier !== null);

    return (
        <div className="keno-payout-container">
            {selectedCount === 0 ? (
                <div className="select-numbers-message">
                    Select 1 - 10 numbers to play
                </div>
            ) : (
                <div className="payout-content">
                    {nonZeroPayouts.map(({ hits, multiplier }) => (
                        <div key={hits} className="payout-item">
                            <div className="hits"><span className="hit-count">{hits}</span> <GiEmerald className='font-bold text-[#00e676]'/></div>
                            <div className="multiplier">{multiplier}x</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default KenoPayoutTable;
