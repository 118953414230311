import { useState, useRef, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { GoldCoin } from '../components/Coins.tsx';

interface ChatMessage {
    id: number;
    message: string;
    type: string;
    user: {
        name: string;
        avatar_url: string;
    };
}

interface RainVariable {
    name: string;
    value: string;
}

export const useChat = () => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState('');
    const [rainAmount, setRainAmount] = useState<string>('0');
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const { session } = useAuth();

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        // Initial fetch of rain amount
        const fetchRain = async () => {
            const { data, error } = await supabase
                .from('variables')
                .select('*')
                .eq('name', 'rain')
                .single();

            if (error) {
                console.error('Error fetching rain:', error);
                return;
            }

            if (data) {
                setRainAmount(data.value);
            }
        };

        // Subscribe to rain changes
        const rainChannel = supabase
            .channel('rain_changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: 'variables',
                    filter: 'name=eq.rain'
                },
                (payload) => {
                    console.log('Rain change received:', payload);
                    if (payload.new) {
                        setRainAmount((payload.new as RainVariable).value);
                    }
                }
            )
            .subscribe();

        // Initial fetch
        fetchRain();

        const fetchMessages = async () => {
            const { data, error } = await supabase
                .from('chat_messages')
                .select(`
                    *,
                    user:chat_users!chat_messages_chat_user_id_fkey (
                        name,
                        avatar_url,
                        earlyadopter,
                        level
                    )
                `)
                .order('created_at', { ascending: false })
                .limit(50);

            if (error) {
                console.error('Error fetching messages:', error);
                return;
            }
            const sortedData = data ? data.reverse() : [];

            setMessages(sortedData); // Set messages in the correct order
            scrollToBottom();
        };

        fetchMessages();

        // Subscribe to new messages
        const channel = supabase
            .channel('chat_messages')
            .on('postgres_changes',
                {
                    event: 'INSERT',
                    schema: 'public',
                    table: 'chat_messages'
                },
                async (payload) => {
                    // Fetch user data for the new message
                    const { data: userData } = await supabase
                        .from('chat_users')
                        .select('name, avatar_url, earlyadopter, level')
                        .eq('chat_id', payload.new.chat_user_id)
                        .single();

                    const newMessage = {
                        ...payload.new,
                        user: userData
                    };

                    setMessages(prev => [...prev, newMessage]);
                    scrollToBottom();
                }
            )
            .on('postgres_changes',
                {
                    event: 'DELETE',
                    schema: 'public',
                    table: 'chat_messages'
                },
                (payload) => {
                    // Remove deleted message from state
                    setMessages(prev => prev.filter(msg => msg.id !== payload.old.id));
                }
            )
            .subscribe();

        // Cleanup subscriptions
        return () => {
            rainChannel.unsubscribe();
            channel.unsubscribe();
        };
    }, []);

    const handleSendMessage = async () => {
        if (!message.trim() || !session?.user?.id) return;

        try {
            const { data, error } = await supabase.rpc('send_chat_message', {
                v_player_id: session.user.id,
                v_message: message.trim()
            });

            if (error) {
                console.error('Error sending message:', error);
                toast.error('Failed to send message');
                return;
            }

            if (!data.success) {
                toast.error(data.error);
                return;
            }

            setMessage('');
        } catch (error) {
            console.error('Error in handleSendMessage:', error);
            toast.error('Failed to send message');
        }
    };
    const formatAnnouncement = (message: string) => {
        // Match parts using regex for flexibility
        const match = message.match(/^(.*?) just won (.*?) playing (.*?) with a (.*?) multiplier!$/);

        if (!match) return message; // If format doesn't match, return the original message

        const [, player, amount, game, multiplier] = match;

        return (
            <>
                <span style={{ color: 'gold', fontWeight: 600 }}>{player}</span>
                {' just won '}
                <GoldCoin
                    style={{
                        width: '16px',
                        height: '16px',
                        marginRight: '4px',
                        marginLeft: '4px',
                    }}
                />
                <span style={{ color: '#05f783', fontWeight: 600 }}>{amount}</span>
                {' playing '}
                <span style={{ color: '#05f783', fontWeight: 600 }}>{game}</span>
                {' with a '}
                <span style={{ color: '#05f783', fontWeight: 600 }}>{multiplier}x</span>
                {' multiplier!'}
            </>
        );
    };
    const formatTips = (message: string) => {
        // Match the structure of the "tipped" message
        const match = message.match(/^(.*?) just tipped (.*?) (\d*\.?\d+)$/);

        if (!match) return message; // If format doesn't match, return the original message

        const [, sender, receiver, amount] = match;

        return (
            <>
                <span style={{ color: 'gold', fontWeight: 600 }}>{sender}</span>
                {' just tipped '}
                <span style={{ color: 'gold', fontWeight: 600 }}>{receiver}</span>
                {' '}
                <GoldCoin
                    style={{
                        width: '16px',
                        height: '16px',
                        marginRight: '4px',
                        marginLeft: '4px',
                    }}
                />
                <span style={{ color: '#05f783', fontWeight: 600 }}>{amount}</span>
            </>
        );
    };
    const formatRainTip = (message: string) => {
        // Match the structure of the rain tip message
        const match = message.match(/^(.*?) has added (.*?) to the rain!$/);
    
        if (!match) return message; // If format doesn't match, return the original message
    
        const [, sender, amount] = match;
    
        return (
            <>
                <span style={{ color: 'gold', fontWeight: 600 }}>{sender}</span>
                {' has added '}
                <GoldCoin
                    style={{
                        width: '16px',
                        height: '16px',
                        marginRight: '4px',
                        marginLeft: '4px',
                    }}
                />
                <span style={{ color: '#05f783', fontWeight: 600 }}>{amount}</span>
                {' to the rain!'}
            </>
        );
    };
    


    return {
        messages,
        message,
        setMessage,
        handleSendMessage,
        messagesEndRef,
        formatAnnouncement,
        formatTips,
        formatRainTip,
        rainAmount
    };
};